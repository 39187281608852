import {
  Box,
  ButtonProps,
  LinkProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useDisclosure
} from '@chakra-ui/react'

export const UnclickableTooltip: React.FC<ButtonProps & LinkProps> = ({
  children,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Popover placement="bottom" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box
          onClick={onOpen}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          w={props.w}
        >
          {children}
        </Box>
      </PopoverTrigger>
      <PopoverContent
        w="276px"
        alignItems="center"
        bg="purple.900"
        borderRadius="3px"
        color="white.100"
        fontSize="13px"
        lineHeight="19.5px"
        fontWeight={700}
        _focus={{ outline: 'none' }}
      >
        <PopoverCloseButton />
        <PopoverBody h="100%" w="100%" py="14px" pl="14px">
          デモ版ではこちらの機能は使えません。
          <br />
          無料トライアルに進むと、
          <br />
          こちらの機能は使えるようになります！
        </PopoverBody>
        <PopoverArrow bg="purple.900" />
      </PopoverContent>
    </Popover>
  )
}

export const WithUnclickableTooltip = (
  isDemo: boolean,
  component: JSX.Element,
  props?: ButtonProps & LinkProps
) => {
  return isDemo ? (
    <UnclickableTooltip {...props}>{component}</UnclickableTooltip>
  ) : (
    component
  )
}
