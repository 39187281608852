import { createIcon } from '@chakra-ui/react'

export const DocumentIcon = createIcon({
  displayName: 'DocumentIcon',
  viewBox: '0 0 24 24',
  path: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.53022 3.74171H14.0149C14.1462 3.74171 14.2544 3.84957 14.2544 3.98163V19.2437C14.2544 19.3758 14.1462 19.4836 14.0149 19.4836H2.53022C2.39833 19.4836 2.2903 19.3756 2.2903 19.2437V3.98163C2.2903 3.84975 2.39833 3.74171 2.53022 3.74171ZM5.32256 14.3869H11.4516C12.0931 14.3869 12.6129 13.8671 12.6129 13.2256C12.6129 12.5841 12.0931 12.0643 11.4516 12.0643H5.32256C4.68105 12.0643 4.16127 12.5841 4.16127 13.2256C4.16127 13.8671 4.68105 14.3869 5.32256 14.3869ZM5.32256 11.8062H11.4516C12.0931 11.8062 12.6129 11.2864 12.6129 10.6449C12.6129 10.0034 12.0931 9.48365 11.4516 9.48365H5.32256C4.68105 9.48365 4.16127 10.0034 4.16127 10.6449C4.16127 11.2864 4.68105 11.8062 5.32256 11.8062ZM5.32256 9.22558H11.4516C12.0931 9.22558 12.6129 8.7058 12.6129 8.06429C12.6129 7.42279 12.0931 6.903 11.4516 6.903H5.32256C4.68105 6.903 4.16127 7.42279 4.16127 8.06429C4.16127 8.7058 4.68105 9.22558 5.32256 9.22558Z"
        fill="#8D8D8D"
        stroke="#8D8D8D"
        strokeWidth="1.03226"
      />
      <path
        d="M17.7098 1.93548V16.129C17.7098 16.6936 17.3353 17.2982 16.5771 17.4879V3.98186C16.5771 2.56824 15.4284 1.41936 14.015 1.41936H4.56165C4.78194 0.901898 5.32367 0.516129 5.98921 0.516129H16.1759C17.0495 0.516129 17.7098 1.18079 17.7098 1.93548Z"
        fill="#8D8D8D"
        stroke="#8D8D8D"
        strokeWidth="1.03226"
      />
    </svg>
  )
})
