import {
  request_basicInformation_taxableType,
  response_basicInformation_taxableType
} from 'src/api'
import { useNewApiClient } from 'src/hooks/useNewApiClient'
import useSWRImmutable from 'swr/immutable'

const { EXEMPTION } = response_basicInformation_taxableType.taxable_type

export const useTaxableType = () => {
  const { apiClient } = useNewApiClient()

  const { data: taxableType, mutate } = useSWRImmutable(
    `/basic_information/taxable_type`,
    async () => {
      const res =
        await apiClient.consumptionTaxablePlayer.getBasicInformationTaxableType()
      return res
    }
  )

  const patchTaxableType = async (
    body: request_basicInformation_taxableType
  ) => {
    await apiClient.consumptionTaxablePlayer.patchBasicInformationTaxableType(
      body
    )
    mutate()
  }

  return {
    taxableType,
    // 課税事業者かどうか
    isTaxed: taxableType && taxableType.taxable_type !== EXEMPTION,
    patchTaxableType
  }
}
