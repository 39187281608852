import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure
} from '@chakra-ui/react'
import { TriangleArrowRightIcon } from 'src/components/common/Icons/TriangleArrowRightIcon'

export type MenuItemType = {
  href: string
  isExternal: boolean
  label: string
}

type Props = {
  label: string
  menuItems: JSX.Element
}

export const LabelWithMenu: React.FC<Props> = ({ label, menuItems }) => {
  const { onOpen, onClose, isOpen } = useDisclosure()
  return (
    <Box py="0px">
      <Menu placement="right-start" isOpen={isOpen}>
        <MenuButton
          color="gray.8D8"
          fontSize="12px"
          height="64px"
          textAlign="left"
          px="24px"
          py="24px"
          w="100%"
          h="50px"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          _hover={{ bg: 'gray.EFE' }}
        >
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Box flex={1} w="100%">
              {label}
            </Box>
            <TriangleArrowRightIcon
              height="20px"
              width="20px"
              fill="gray.400"
            />
          </Box>
        </MenuButton>
        <MenuList
          minWidth="none"
          w="180px"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          {menuItems}
        </MenuList>
      </Menu>
    </Box>
  )
}
