import { useContext, useMemo } from 'react'
import { ApiClient } from 'src/api'
import GlobalContext from 'src/context/GlobalContext'

// ----------------------------------------------------------------------

export const useNewApiClient = () => {
  const {
    state: {
      loginUser: { uid, accessToken, client }
    }
  } = useContext(GlobalContext)
  const apiClient = useMemo(() => {
    const headers = {}
    if (uid && accessToken && client) {
      Object.assign(headers, {
        'Content-Type': 'application/json',
        'token-type': 'Bearer',
        uid,
        'access-token': accessToken,
        client
      })
    }

    return new ApiClient({
      BASE: process.env.NEXT_PUBLIC_APP_API_URL,
      HEADERS: headers,
      WITH_CREDENTIALS: true
    })
  }, [uid, accessToken, client])
  return { apiClient }
}
