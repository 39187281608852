import { useNewApiClient } from 'src/hooks/useNewApiClient'
import useSWRImmutable from 'swr/immutable'

export const useIsTestUser = () => {
  const { apiClient } = useNewApiClient()

  const { data: isTestUser } = useSWRImmutable(
    `/user/is_test_user`,
    async () => {
      const res = await apiClient.user.getUserIsTestUser()
      return res.is_test_user
    }
  )

  return {
    isTestUser
  }
}
