import { useContext } from 'react'
import GlobalContext from 'src/context/GlobalContext'
import useSWRImmutable from 'swr/immutable'
import { useNewApiClient } from './useNewApiClient'

export const useActiveSubscription = () => {
  const { isLogin } = useContext(GlobalContext)
  const { apiClient } = useNewApiClient()

  const { data: activeSubscription, mutate } = useSWRImmutable(
    `/subscriptions/active`,
    async () => {
      if (!isLogin()) return null

      const res = await apiClient.payment.getSubscriptionsActive()
      return res
    }
  )

  return {
    activeSubscription,
    mutateActiveSubscription: mutate
  }
}
