import { useApiClient } from './useApiClient'

export const useStripeCustomerPortal = () => {
  const { apiClient } = useApiClient()

  const getStripeCustomerPortalLink = async (
    returnUrl: string
  ): Promise<string> => {
    const { customer_portal_url } = await apiClient.getStripeCustomerPortal(
      returnUrl
    )
    return customer_portal_url
  }

  return {
    getStripeCustomerPortalLink
  }
}
