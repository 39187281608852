import { useBoolean } from '@chakra-ui/react'
import { useApiClient } from './useApiClient'

export const useATLink = () => {
  const [isLoading, { on, off }] = useBoolean(false)
  const { apiClient } = useApiClient()

  const redirectToAT = async (isSameTab?: boolean) => {
    on()
    const { redirect_to } = await apiClient.getAccountTrackerLink(location.href)
    if (isSameTab) {
      window.location.href = redirect_to
    } else {
      const windowReference = window.open('', '_blank')
      if (windowReference) windowReference.location = redirect_to
    }
    off()
  }

  const redirectToATByAccountId = async (
    accountId: number,
    isSameTab?: boolean
  ) => {
    on()
    const { redirect_to } = await apiClient.getAccountTrackerLink(
      location.href,
      accountId
    )
    if (isSameTab) {
      window.location.href = redirect_to
    } else {
      const windowReference = window.open('', '_blank')
      if (windowReference) windowReference.location = redirect_to
    }
    off()
  }

  return { redirectToAT, redirectToATByAccountId, isLoading }
}
