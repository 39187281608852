import { Link, MenuItem } from '@chakra-ui/react'
import NextLink from 'next/link'
import { response_basicInformation_taxableType } from 'src/api'
import { useTaxableType } from 'src/hooks/basic_information/useTaxableType'
import { PAGE_PATH } from 'src/routes'
import { LabelWithMenu, MenuItemType } from './LabelWithMenu'

const { EXEMPTION } = response_basicInformation_taxableType.taxable_type

const MenuItems: MenuItemType[] = [
  {
    isExternal: false,
    href: PAGE_PATH.fixed_assets.index,
    label: '固定資産台帳'
  },
  {
    isExternal: false,
    href: PAGE_PATH.opening_balance.index,
    label: '開始残高の設定'
  },
  {
    isExternal: false,
    href: PAGE_PATH.taxable_type,
    label: '課税形式の設定'
  },
  {
    isExternal: false,
    href: PAGE_PATH.export_books.index,
    label: '帳簿データのダウンロード'
  }
]

export const DetailSettingsMenu: React.FC = () => {
  const { taxableType } = useTaxableType()
  let menuItems = MenuItems

  if (taxableType?.taxable_type !== EXEMPTION) {
    menuItems = [
      ...menuItems,
      {
        isExternal: false,
        href: PAGE_PATH.taxes,
        label: '税区分の設定'
      }
    ]
  }

  return (
    <LabelWithMenu
      label="詳細設定"
      menuItems={
        <>
          {menuItems.map((link, key) => {
            return (
              <NextLink href={link.href} key={key} passHref>
                <Link>
                  <MenuItem color="gray.800" fontSize="13px">
                    {link.label}
                  </MenuItem>
                </Link>
              </NextLink>
            )
          })}
        </>
      }
    />
  )
}
