import { Box, useBoolean, useDisclosure } from '@chakra-ui/react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
import { useEffectOnce } from 'react-use'
import { Highlight } from '../components/Highlight'

// デモにはRouteとStepの概念があり、各RouteはdemoStepIndex = 0からスタートする
type DemoContextType = {
  isDemo: boolean
  demoRouteIndex: number
  setDemoRouteIndex: (index: number) => void
  demoStepIndex: number
  setDemoStepIndex: (index: number) => void
  nextDemoStep: VoidFunction
  isDemoRunning: boolean
  onIsDemoRunning: VoidFunction
  offIsDemoRunning: VoidFunction
  isHighlightenning: boolean
  onIsHighlightenning: VoidFunction
  offIsHighlightenning: VoidFunction
  highlight: JSX.Element | null
  isDemoNavigationModalOpen: boolean
  onDemoNavigationModalOpen: VoidFunction
  onDemoNavigationModalClose: VoidFunction
  isStartGuideShown: boolean
  offIsStarGuideShown: VoidFunction
  onIsStartGuideShown: VoidFunction
  unreadArray: [number, number, number, number]
  setUnreadArray: (array: [number, number, number, number]) => void
}

const LOCAL_STORAGE_KEY_DEMO_SP_UNREAD = 'zeione_demo_sp_unread'
export const getParsedDemoSPUnreadFromLocalStorage = () =>
  JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEY_DEMO_SP_UNREAD) || '[1, 1, 1, 1]'
  ) as [number, number, number, number]
export const setDemoSPUnreadToLocalStorage = (
  index: number,
  unread: number
) => {
  const unreadArray = getParsedDemoSPUnreadFromLocalStorage() as [
    number,
    number,
    number,
    number
  ]
  localStorage.setItem(
    LOCAL_STORAGE_KEY_DEMO_SP_UNREAD,
    JSON.stringify(
      unreadArray.map((unreadFromLocalStorage, i) =>
        i === index ? unread : unreadFromLocalStorage
      )
    )
  )
}

const DemoContext = createContext<DemoContextType>({} as DemoContextType)

export const DemoContextProvider: React.FC<{ isDemo?: boolean }> = ({
  children,
  isDemo
}) => {
  const [
    isStartGuideShown,
    { off: offIsStarGuideShown, on: onIsStartGuideShown }
  ] = useBoolean(true)
  const [isDemoRunning, { off: offIsDemoRunning, on: onIsDemoRunning }] =
    useBoolean()
  const [
    isHighlightenning,
    { off: offIsHighlightenning, on: onIsHighlightenning }
  ] = useBoolean()
  const [demoRouteIndex, setDemoRouteIndex] = useState(0)
  const [demoStepIndex, setDemoStepIndex] = useState(0)
  // モーダルの制御
  const {
    isOpen: isDemoNavigationModalOpen,
    onOpen: onDemoNavigationModalOpen,
    onClose: onDemoNavigationModalClose
  } = useDisclosure()

  const nextDemoStep = useCallback(() => {
    setDemoStepIndex(demoStepIndex + 1)
  }, [demoStepIndex])

  const handleClickHighlight = useCallback(() => {
    // ガイドが出てるときは反応させない
    if (isDemoRunning) {
      return
    }
    if (isDemo === undefined || isDemo) {
      onIsHighlightenning()
      setTimeout(offIsHighlightenning, 500)
    }
  }, [isDemo, isDemoRunning])

  const highlightComponent = useMemo(() => <Highlight />, [])

  const [unreadArray, setUnreadArray] = useState<
    [number, number, number, number]
  >([1, 1, 1, 1])

  useEffectOnce(() => {
    setUnreadArray(getParsedDemoSPUnreadFromLocalStorage())
  })

  return (
    <DemoContext.Provider
      value={{
        isDemo: isDemo === undefined ? true : false,
        demoRouteIndex,
        setDemoRouteIndex,
        demoStepIndex,
        setDemoStepIndex,
        nextDemoStep,
        isDemoRunning,
        onIsDemoRunning,
        offIsDemoRunning,
        isHighlightenning,
        onIsHighlightenning,
        offIsHighlightenning,
        highlight: isHighlightenning ? highlightComponent : null,
        isDemoNavigationModalOpen,
        onDemoNavigationModalOpen,
        onDemoNavigationModalClose,
        isStartGuideShown,
        offIsStarGuideShown,
        onIsStartGuideShown,
        unreadArray,
        setUnreadArray
      }}
    >
      <Box w="100%" h="100%" onClick={handleClickHighlight}>
        {children}
      </Box>
    </DemoContext.Provider>
  )
}

export const useDemoContext = () => {
  const context = useContext(DemoContext)
  if (!context)
    throw new Error(
      'Tax Task Page Context must be used inside GlobalContextProvider'
    )
  return context
}
