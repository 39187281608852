import { createIcon } from '@chakra-ui/react'

export const ChatIcon = createIcon({
  displayName: 'ChatIcon',
  viewBox: '0 0 20 20',
  path: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Ic/Filled/comment">
        <path
          id="Vector"
          d="M18.3333 2.5H1.66667V15H5.41667V17.0833L9.58333 15H18.3333V2.5Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="Vector_2">
          <path d="M5.83333 8.125V9.375V8.125Z" fill="white" />
          <path
            d="M5.83333 8.125V9.375"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Vector_3">
          <path d="M10 8.125V9.375V8.125Z" fill="white" />
          <path
            d="M10 8.125V9.375"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <g id="Vector_4">
          <path d="M14.1667 8.125V9.375V8.125Z" fill="white" />
          <path
            d="M14.1667 8.125V9.375"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  )
})
