// Refs. https://docs.google.com/spreadsheets/d/1-rRVqD_Ps80gmiIUnzgUXqz-h056gjgeZ50dLMlClDk/edit?pli=1#gid=0
export const ERROR_STATUSES = ['E', 'A']

export interface GetAccounts {
  status: 'success'
  accounts: Account[]
}

export interface Account {
  id: number
  name: string
  /**
   *  null:「その他の普通預金」など未登録の口座, 0: 正常, E: エラー, A: 追加認証要求, C: キャンセル, S: 実行中
   */
  account_type: -1 | 0 | 1
  is_syncing: boolean //(取引情報が同期中の場合TRUE)
  /**
   * null:「その他の普通預金」など未登録の口座, 0: 正常, E: エラー, A: 追加認証要求, C: キャンセル, S: 実行中
   */
  at_status: null | '0' | 'E' | 'A' | 'C' | 'S'
}
