import { createIcon } from '@chakra-ui/react'

export const ReportMenuIcon = createIcon({
  displayName: 'ReportMenuIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <path
        d="M10 18.3346C14.6024 18.3346 18.3333 14.6037 18.3333 10.0013C18.3333 5.39893 14.6024 1.66797 10 1.66797C5.39762 1.66797 1.66667 5.39893 1.66667 10.0013C1.66667 14.6037 5.39762 18.3346 10 18.3346Z"
        strokeWidth="1.66667"
      />
      <path d="M7.5 9.16797H12.5H7.5Z" fill="white" />
      <path
        d="M7.5 9.16797H12.5"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
      <path d="M7.5 11.668H12.5H7.5Z" />
      <path
        d="M7.5 11.668H12.5"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
      <path d="M10.0035 9.16797V14.168V9.16797Z" fill="white" />
      <path
        d="M10.0035 9.16797V14.168"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
      <path
        d="M12.5 6.25L10 8.75L7.5 6.25"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
    </>
  )
})
