import { createIcon } from '@chakra-ui/react'

export const JournalListIcon = createIcon({
  displayName: 'JournalListIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <path
        d="M2.08398 4.16602L3.33398 5.41602L5.83398 2.91602"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
      <path
        d="M2.08398 10L3.33398 11.25L5.83398 8.75"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
      <path
        d="M2.08398 15.834L3.33398 17.084L5.83398 14.584"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
      <path d="M8.75 10H17.9167" strokeWidth="1.66667" strokeLinecap="square" />
      <path
        d="M8.75 15.834H17.9167"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
      <path
        d="M8.75 4.16602H17.9167"
        strokeWidth="1.66667"
        strokeLinecap="square"
      />
    </>
  )
})
