import { useState } from 'react'
import { useMount } from 'react-use'
import { parseCookie } from 'src/common/CookieManager'
import { COOKIE_IS_ADMIN_LOGIN_KEY } from 'src/constants'

export const useIsAdminLogin = () => {
  const [isAdminLogin, setIsAdminLogin] = useState(false)

  useMount(() => {
    const cookie = parseCookie()
    const isAdminLogin = cookie?.[COOKIE_IS_ADMIN_LOGIN_KEY]
    setIsAdminLogin(isAdminLogin === 'true')
  })
  return { isAdminLogin }
}
