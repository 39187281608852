import { useContext, useMemo } from 'react'
import ApiClient from 'src/clients/ApiClient'
import GlobalContext from 'src/context/GlobalContext'

// ----------------------------------------------------------------------

export const useApiClient = () => {
  const context = useContext(GlobalContext)
  if (!context)
    throw new Error('api client must be used inside GlobalContextProvider')

  const { uid, accessToken, client } = context.state.loginUser

  const apiClient = useMemo(
    () => new ApiClient(uid, accessToken, client),
    [uid, accessToken, client]
  )

  return { apiClient }
}
