import { Box } from '@chakra-ui/react'
import { demoFlashAnimation } from 'src/styles/animations'

export const Highlight: React.FC = () => {
  return (
    <Box
      pos="absolute"
      left={0}
      top={0}
      w="100%"
      h="100%"
      bgColor="rgba(232, 108, 55, 0.5)"
      animation={demoFlashAnimation}
    />
  )
}
