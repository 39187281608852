import useSWRImmutable from 'swr/immutable'
import { useNewApiClient } from '../useNewApiClient'

export const useUserInfo = () => {
  const { apiClient } = useNewApiClient()
  const { data } = useSWRImmutable('/user/info', async () => {
    const res = await apiClient.user.getUserInfo()
    return res
  })

  return {
    userInfo: data
  }
}
