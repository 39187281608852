import { createIcon } from '@chakra-ui/react'

export const TaskListIcon = createIcon({
  displayName: 'TaskListIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <path d="M10 1.66602V17.0827" strokeWidth="2" strokeLinecap="square" />
      <path
        d="M10 3.33203H16.4773L17.5 4.9987L16.4773 6.66536H10V3.33203Z"
        strokeWidth="2"
      />
      <path
        d="M10 9.16602H3.52273L2.5 10.8327L3.52273 12.4993H10V9.16602Z"
        strokeWidth="2"
      />
      <path d="M6.66602 17.5H13.3327" strokeWidth="2" strokeLinecap="square" />
    </>
  )
})
