import { Box, Flex, Spinner, Text, useBoolean } from '@chakra-ui/react'
import dayjs from 'dayjs'
import router from 'next/router'
import { useActiveSubscription } from 'src/hooks/useActiveSubscription'
import { useStripeCustomerPortal } from 'src/hooks/useStripeCustomerPortal'
import { PAGE_PATH } from 'src/routes'

export const ActiveSubscriptionAlert: React.FC = () => {
  const [isLoading, { on }] = useBoolean(false)
  const { activeSubscription } = useActiveSubscription()

  const { getStripeCustomerPortalLink } = useStripeCustomerPortal()

  const redirectToStripeCustomerPortal = async () => {
    on()
    const url = await getStripeCustomerPortalLink(location.href)
    location.assign(url)
  }
  return activeSubscription?.is_freetrial ? (
    activeSubscription?.has_stripe_subscription ? (
      <Box p="0px 16px 0">
        <Box
          p="16px 20px"
          gap="10px"
          background="gray.100"
          borderRadius="12px"
          textAlign="center"
          cursor="pointer"
          onClick={() => {
            if (!isLoading) redirectToStripeCustomerPortal()
          }}
        >
          <Text
            fontSize="14px"
            fontWeight="500"
            color="gray.900"
            lineHeight="1.0"
          >
            無料トライアル中
          </Text>
          <Box
            display="inline-flex"
            mt="10px"
            p="8px 16px"
            background="gray.900"
            borderRadius="2px"
            fontSize="13px"
            lineHeight="1.0"
            fontWeight="500"
            color="white.primary"
          >
            {isLoading && (
              <>
                <Spinner w="16px" h="16px" />
                &nbsp;
              </>
            )}
            残り
            {dayjs(activeSubscription.ended_at).diff(dayjs(), 'day')}日
          </Box>
        </Box>
      </Box>
    ) : (
      <Box
        pt="4px"
        px="10px"
        borderTop="1px solid"
        borderColor="gray.200"
        cursor="pointer"
      >
        <Flex
          boxShadow="0px 4px 20px 0px rgba(0, 0, 0, 0.2)"
          bgColor="primary.orange"
          borderRadius="8px"
          p="10px"
          color="white.100"
          gap="4px"
          align="center"
          flexDir="column"
          onClick={() => {
            router.push(PAGE_PATH.pricing_freetrial)
          }}
        >
          <Box
            display="inline-block"
            py="6px"
            px="8px"
            bgColor="orange.800"
            fontWeight={500}
            lineHeight={'18.2px'}
            fontSize="13px"
            borderRadius="4px"
            width="100%"
            textAlign="center"
          >
            無料でお試し中
            <br />
            （あと
            {dayjs(activeSubscription?.ended_at).diff(dayjs(), 'day')}
            日）
          </Box>
          <Text
            fontWeight={700}
            lineHeight="19.6px"
            fontSize="14px"
            textAlign="center"
            marginTop="4px"
            marginBottom="4px"
          >
            有料プランに移行する
          </Text>
        </Flex>
      </Box>
    )
  ) : null
}
