import { response_user_userType } from 'src/api'
import { useNewApiClient } from 'src/hooks/useNewApiClient'
import useSWRImmutable from 'swr/immutable'

const { MARUNAGE } = response_user_userType.user_type

export const useUserType = () => {
  const { apiClient } = useNewApiClient()

  const { data: userType, mutate } = useSWRImmutable(
    `/user/user_type`,
    async () => {
      const res = await apiClient.user.getUserUserType()
      return res.user_type
    }
  )

  return {
    userType,
    isUltra: userType === MARUNAGE,
    mutateUserType: mutate
  }
}
