import useSWR from 'swr/immutable'
import { useApiClient } from './useApiClient'

export const useProcessingYear = () => {
  const { apiClient } = useApiClient()

  const { data: processing_year, mutate } = useSWR(
    `/users/processing_year`,
    async () => {
      const res = await apiClient.getProcessingYear()
      return res
    }
  )

  const yearStrings = processing_year
    ? processing_year.processing_year
    : new Date().getFullYear()

  const processing_year_firstday = `${yearStrings}-01-01`
  const processing_year_lastday = `${yearStrings}-12-31`
  const processing_year_firstday_slash = `${yearStrings}/01/01`
  const processing_year_lastday_slash = `${yearStrings}/12/31`

  const patchProcessingYear = async (year: number) => {
    const res = await apiClient.patchProcessingYear(year)
    await mutate()
    return res
  }

  return {
    processing_year,
    processing_year_firstday,
    processing_year_lastday,
    processing_year_firstday_slash,
    processing_year_lastday_slash,
    patchProcessingYear
  }
}
