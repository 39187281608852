import { keyframes } from '@chakra-ui/react'

const flashKeyframes = keyframes`
0%,100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
`
export const demoFlashAnimation = `${flashKeyframes} 1s linear infinite`

const targetMarkKeyframes = keyframes`
 0% {
  transform: scale(1);
  opacity: 0.5;
 }

 100% {
  transform: scale(3);
  opacity: 0;
 }
`
export const targetMarkAnimation = `${targetMarkKeyframes} 1s linear infinite`

const refreshIconKeyframes = keyframes`
 0% {
  transform: rotate(0deg);
 }

 100% {
  transform: rotate(359deg);
 }
`
export const refreshIconAnimation = `${refreshIconKeyframes} 1s linear infinite`

const progressbarKeyFrames = (percent: number | undefined) => keyframes`
 from {
  width: 0%;
 }

 to {
  width: ${percent || 0}%;
 }
`

export const progressbarAnimation = (percent: number | undefined) =>
  `${progressbarKeyFrames(percent)} 1s ease-in forwards`
