import { createIcon } from '@chakra-ui/react'

export const SidemenuCategoryIcon = createIcon({
  displayName: 'SidemenuCategoryIcon',
  viewBox: '0 0 20 20',
  path: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Ic/Line/category">
        <path
          id="Vector"
          d="M7.5 2.5H3.33333C2.8731 2.5 2.5 2.8731 2.5 3.33333V7.5C2.5 7.96025 2.8731 8.33333 3.33333 8.33333H7.5C7.96025 8.33333 8.33333 7.96025 8.33333 7.5V3.33333C8.33333 2.8731 7.96025 2.5 7.5 2.5Z"
          stroke="#8D8D8D"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M7.5 11.6665H3.33333C2.8731 11.6665 2.5 12.0396 2.5 12.4998V16.6665C2.5 17.1268 2.8731 17.4998 3.33333 17.4998H7.5C7.96025 17.4998 8.33333 17.1268 8.33333 16.6665V12.4998C8.33333 12.0396 7.96025 11.6665 7.5 11.6665Z"
          stroke="#8D8D8D"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M16.6665 2.5H12.4998C12.0396 2.5 11.6665 2.8731 11.6665 3.33333V7.5C11.6665 7.96025 12.0396 8.33333 12.4998 8.33333H16.6665C17.1268 8.33333 17.4998 7.96025 17.4998 7.5V3.33333C17.4998 2.8731 17.1268 2.5 16.6665 2.5Z"
          stroke="#8D8D8D"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M16.6665 11.6665H12.4998C12.0396 11.6665 11.6665 12.0396 11.6665 12.4998V16.6665C11.6665 17.1268 12.0396 17.4998 12.4998 17.4998H16.6665C17.1268 17.4998 17.4998 17.1268 17.4998 16.6665V12.4998C17.4998 12.0396 17.1268 11.6665 16.6665 11.6665Z"
          stroke="#8D8D8D"
          strokeWidth="2"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
})
