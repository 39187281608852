import { createIcon } from '@chakra-ui/react'

export const TriangleArrowRightIcon = createIcon({
  displayName: 'TriangleArrowRightIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 5.41699L13 10.417L8 15.417V5.41699Z"
          strokeLinejoin="bevel"
        />
      </svg>
    </>
  )
})
