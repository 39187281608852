import { useNewApiClient } from 'src/hooks/useNewApiClient'
import useSWR from 'swr/immutable'

export const useUltraTopicsCount = () => {
  const { apiClient } = useNewApiClient()

  const { data: count } = useSWR('/topics/opening_count', async () => {
    const res = await apiClient.messaging.getTopicsOpeningCount()
    return res.count
  })

  return {
    count
  }
}
