import { Divider, Link, MenuItem, Spinner, useBoolean } from '@chakra-ui/react'
import { useContext } from 'react'
import GlobalContext from 'src/context/GlobalContext'
import { useActiveSubscription } from 'src/hooks/useActiveSubscription'
import { useStripeCustomerPortal } from 'src/hooks/useStripeCustomerPortal'
import { useIsTestUser } from 'src/hooks/useUserIsTestUser'
import { PAGE_PATH, PRIVACY_POLICY_PATH } from 'src/routes'
import { LabelWithMenu, MenuItemType } from './LabelWithMenu'

export const UserSettingsMenu: React.FC = () => {
  const [isLoading, { on, off }] = useBoolean(false)
  const [isDownloading, { on: onDownloading, off: offDownloading }] =
    useBoolean(false)
  const context = useContext(GlobalContext)
  const { logout } = context
  const { activeSubscription } = useActiveSubscription()
  const { isTestUser } = useIsTestUser()
  const { getStripeCustomerPortalLink } = useStripeCustomerPortal()

  const redirectToStripeCustomerPortal = async () => {
    on()
    const url = await getStripeCustomerPortalLink(location.href)
    const windowReference = window.open('', '_blank')
    if (windowReference) windowReference.location = url
    off()
  }
  const MenuItems: MenuItemType[] = [
    {
      isExternal: true,
      href: 'https://tax-hero.zendesk.com/hc/ja/requests/new',
      label: 'お問い合わせ'
    },
    {
      isExternal: true,
      href: PAGE_PATH.questions.pre_service,
      label: '進め方診断'
    },
    {
      isExternal: true,
      href: 'https://thoughtful-argon-5a8.notion.site/c6c76467012046cebec0833c830da7e4',
      label: '利用規約'
    },
    {
      isExternal: true,
      href: PRIVACY_POLICY_PATH,
      label: 'プライバシーポリシー'
    },
    {
      label: 'お支払い方法',
      isExternal: false,
      href:
        activeSubscription?.is_freetrial &&
        !activeSubscription?.has_stripe_subscription
          ? PAGE_PATH.pricing_freetrial
          : '/users/stripe_customer_portal'
    },
    {
      isExternal: true,
      label: 'よくある質問',
      href: 'https://thoughtful-argon-5a8.notion.site/ef17497ae39a4898949c92f331e6d6f4'
    }
  ]

  return (
    <LabelWithMenu
      label="ユーザー設定"
      menuItems={
        <>
          <MenuItem onClick={logout} color="gray.800" fontSize="13px">
            ログアウト
          </MenuItem>

          <Divider color="gray.F0F" mx="12px" w="calc(100% - 24px)" />

          {MenuItems.map((link, key) => {
            return link.href === '/users/stripe_customer_portal' ? (
              isTestUser === true ? (
                <MenuItem
                  onClick={() =>
                    alert(
                      '管理者はStripeカスタマーポータルにアクセスできません。'
                    )
                  }
                  color="gray.800"
                  fontSize="13px"
                >
                  {link.label}
                </MenuItem>
              ) : (
                <Link key={key}>
                  <MenuItem
                    color="gray.800"
                    fontSize="13px"
                    onClick={redirectToStripeCustomerPortal}
                  >
                    {isLoading && <Spinner w="16px" h="16px" />}
                    {link.label}
                  </MenuItem>
                </Link>
              )
            ) : (
              <Link
                target={link.isExternal ? '_blank' : ''}
                rel="noopener noreferrer"
                href={link.href}
                key={key}
              >
                <MenuItem color="gray.800" fontSize="13px">
                  {link.label}
                </MenuItem>
              </Link>
            )
          })}
        </>
      }
    />
  )
}
