import { createIcon } from '@chakra-ui/react'

export const InvoicePageIcon = createIcon({
  displayName: 'InvoicePageIcon',
  viewBox: '0 0 20 20',
  path: (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.833 1.66797H4.16634C3.7061 1.66797 3.33301 2.04106 3.33301 2.5013V17.5013C3.33301 17.9616 3.7061 18.3346 4.16634 18.3346H15.833C16.2933 18.3346 16.6663 17.9616 16.6663 17.5013V2.5013C16.6663 2.04106 16.2933 1.66797 15.833 1.66797Z"
          strokeWidth="1.66667"
          strokeLinecap="square"
        />
        <path d="M11.6667 6.66536C11.6667 7.24066 11.4335 7.76149 11.0565 8.13849C10.6795 8.51553 10.1586 8.7487 9.58333 8.7487C8.43275 8.7487 7.5 7.81595 7.5 6.66536C7.5 5.51478 8.43275 4.58203 9.58333 4.58203C10.7339 4.58203 11.6667 5.51478 11.6667 6.66536Z" />
        <path
          d="M12.5 9.58203L11.0565 8.13849L12.5 9.58203ZM11.0565 8.13849C11.4335 7.76149 11.6667 7.24066 11.6667 6.66536C11.6667 5.51478 10.7339 4.58203 9.58333 4.58203C8.43275 4.58203 7.5 5.51478 7.5 6.66536C7.5 7.81595 8.43275 8.7487 9.58333 8.7487C10.1586 8.7487 10.6795 8.51553 11.0565 8.13849Z"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="square"
        />
        <path
          d="M7.08301 12.5H12.9163"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="square"
        />
        <path
          d="M7.08301 15H9.99967"
          stroke="white"
          strokeWidth="1.66667"
          strokeLinecap="square"
        />
      </svg>
    </>
  )
})
