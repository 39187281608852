import { response_accounts } from 'src/api'
import { Account, ERROR_STATUSES } from 'src/interfaces/api/GetAccounts'
import useSWR from 'swr'
import { useApiClient } from './useApiClient'

export type ItemOfAccount = {
  account_id: number
  type: Account['account_type']
  at_status: Account['at_status']
} & Pick<Account, 'is_syncing' | 'at_status' | 'name'>
export type Accounts = {
  label: string
  items: ItemOfAccount[]
}

export const transformAccounts = (
  accounts: Account[] | response_accounts['accounts']
): Accounts => {
  const items = accounts.map((item) => {
    return {
      ...item,
      // 口座連携ステータス確認するときはここをコメントアウト
      // is_syncing: i === 0 ? Math.random() < 0.9 : false,
      name: item.name,
      type: item.account_type as Account['account_type'],
      account_id: item.id,
      at_status: item.at_status as Account['at_status']
    }
  })

  return { label: '入出金手段', items }
}

const transformAccountsForReactSelect = (accounts: Accounts) => {
  const options = accounts?.items.map((v) => ({
    value: v.account_id,
    label: v.name
  }))

  const object = {
    label: '入出金手段',
    options: options
  }
  return object
}

export const useAccounts = (accountType?: 0 | 1) => {
  const { apiClient } = useApiClient()

  const { data: accounts, mutate } = useSWR(
    `/accounts${accountType ? `?account_type=${accountType}` : ''}`,
    async () => {
      const res = await apiClient.getAccounts()
      return transformAccounts(res.accounts)
    }
  )

  const accounts_for_react_select = accounts
    ? transformAccountsForReactSelect(accounts)
    : { label: '入出金手段', options: [] }

  const accountGetter = (account_id: number | null): string | null => {
    const targetItem = accounts?.items.find(
      (item) => item.account_id === account_id
    )
    return targetItem?.name || null
  }

  const postAccountsForDev = async () => {
    await apiClient.postAccountsForDev()
    await mutate()
  }

  const connectedAccounts = accounts?.items?.filter((account) => {
    // 現金やその他の預金はidが負の値をとる
    return account.account_id > 0
  })

  return {
    accounts,
    accounts_for_react_select,
    isNotConnected: connectedAccounts && connectedAccounts.length === 0,
    failedAccounts: accounts?.items?.filter((account) =>
      ERROR_STATUSES.includes(account.at_status || '')
    ),
    accountGetter,
    postAccountsForDev,
    mutateAccounts: mutate
  }
}
